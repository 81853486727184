<script setup>
import { ref } from 'vue';
import { Head, Link, useForm } from '@inertiajs/vue3';
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import TextInput from '@/Components/TextInput.vue';
import MainButton from '@/Components/MainButton.vue';
import ShowPasswordToggle from '@/Components/ShowPasswordToggle.vue';
import NewFooter from '@/Components/NewFooter.vue';

import Rectangle4 from '../../../../public/images/PurpleSmoke2.jpg';
import Registration from '../../../../public/images/GirlsWhispering.png';

defineProps({
  canResetPassword: Boolean,
  status: String
});

const form = useForm({
  email: '',
  password: '',
  remember: false
});

const submit = () => {
  form
    .transform(data => ({
      ...data,
      remember: form.remember ? 'on' : ''
    }))
    .post(route('login'), {
      onFinish: () => form.reset('password')
    });
};

const showPassword = ref(false);
</script>

<template>

  <Head title="Login" />

  <div class="flex h-[870px] w-full flex-col md:h-[80vh] md:flex-row">
    <!-- Left Side with Images -->
    <div class="relative flex overflow-hidden h-1/4 md:h-full md:w-2/5">
      <img
        :src="Rectangle4"
        alt=""
        class="absolute inset-0 object-cover w-full h-full"
      />
      <div class="absolute py-16 -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 h-80 w-60 rounded-2xl md:py-0">
        <img
          :src="Registration"
          alt="Login Image"
          class="h-full w-full !rounded-xl border border-purple-400 object-cover opacity-50"
        />
      </div>
    </div>

    <!-- Right Side Form -->
    <div class="p-4 bg-white h-1/2 md:h-full md:w-3/5">
      <h1 class="mt-6 mb-6 text-4xl font-semibold font-title md:mt-20">
        Welcome Back
      </h1>

      <div
        v-if="status"
        class="mb-4 text-sm font-medium text-green-400"
      >
        {{ status }}
      </div>

      <form @submit.prevent="submit">
        <!-- Email Input -->
        <div>
          <InputLabel
            for="email"
            value="Email"
          />
          <TextInput
            id="email"
            v-model="form.email"
            type="email"
            class="block w-full mt-1"
            required
            autofocus
            autocomplete="username"
          />
          <InputError
            class="mt-2"
            :message="form.errors.email"
          />
        </div>

        <!-- Password Input -->
        <div class="mt-4">
          <InputLabel
            for="password"
            value="Password"
          />
          <TextInput
            id="password"
            v-model="form.password"
            :type="showPassword ? 'text' : 'password'"
            class="block w-full mt-1"
            required
            autocomplete="current-password"
          />
          <InputError
            class="mt-2"
            :message="form.errors.password"
          />
        </div>

        <!-- Show Password Toggle -->
        <div class="block mt-4">
          <ShowPasswordToggle v-model="showPassword" />
        </div>

        <!-- Remember Me Checkbox -->
        <div class="block mt-4">
          <label class="flex items-center">
            <Checkbox
              v-model:checked="form.remember"
              name="remember"
            />
            <span class="text-sm text-gray-800 ms-2">Remember me</span>
          </label>
        </div>

        <!-- Submit Button -->
        <div class="flex flex-col w-full mt-4">
          <MainButton
            class="w-full justify-center !rounded-full"
            :class="{ 'opacity-25': form.processing }"
            :disabled="form.processing"
          >
            Log in
          </MainButton>
          <Link
            :href="route('register')"
            class="mt-2 text-sm transition duration-200 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:hover:text-gray-100 dark:focus:ring-offset-gray-800"
          >
          Oops! Need to register?
          </Link>
        </div>
      </form>
    </div>
  </div>

  <NewFooter class="-mt-52 md:mt-0" />
</template>
